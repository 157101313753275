import React from "react";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import LinkToTour from "../components/LinkToTour";
import famImage from "../images/image-for-families-lg.png";
import styled from "@emotion/styled";
import ParentRouteHandler from "../components/ParentRouteHandler";

const ForFamilies = ({ location }) => {
  const ImageTag = styled.img`
    margin: 15px auto;
    width: 100%;
  `;

  const Text = styled.p`
    font-size: 16px;
  `;
  const isNavigatedFromNavMenu = location.search.includes("from-nav=1");

  return (
    <>
      {isNavigatedFromNavMenu && <ParentRouteHandler title={"back"} />}
      <Seo title="Discover the Broad with your family" />
      <div className="heading">
        <HeadingWithSub
          main={`Discover the Broad with your family`}
          subTitle={``}
        />

        <ImageTag src={famImage} alt="family" />

        <Text>
          Welcome! The questions and activities in this guide encourage families
          to explore artworks at The Broad together.
        </Text>
        <Text>
          The best way for families to experience art is to imagine everything
          as fair game. There’s no observation too small or too obvious; there’s
          nothing too silly to be discussed.
        </Text>
        <Text>
          Let your instincts and your child’s curiosity guide you. Children
          often pick up on details that adults may not notice. Let their fresh
          eyes and excitement influence how you look at art, too!
        </Text>

        <LinkToTour
          title={"Tips for Families"}
          subtitle={"Things to remember during your visit"}
          link={"/tips-for-families"}
          displayImage={false}
        />

        <LinkToTour
          title={"Let's Look at Art Together"}
          subtitle={"Art descriptions and questions for kids"}
          link={"/look-at-art-together"}
          displayImage={false}
        />

        <LinkToTour
          title={"Explore the Broad Collection"}
          subtitle={"Fill in the blanks"}
          link={"/explore-the-broad"}
          displayImage={false}
        />

        <LinkToTour
          title={"The Broad Scavenger Hunt"}
          subtitle={"Go On a Quest for Art"}
          link={"/scavenger-hunt"}
          displayImage={false}
        />

        <LinkToTour
          title={"Looking with LeVar Burton"}
          subtitle={"Kids Tour"}
          link={"/kids-audio-tour?families=1"}
          displayImage={false}
        />

        <LinkToTour
          title={"General Questions to Ask Kids"}
          subtitle={"Get your kids talking"}
          link={"/general-questions-kids"}
          displayImage={false}
        />
      </div>
    </>
  );
};

export default ForFamilies;
